import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  error_description: string;

  constructor(private auth: AuthService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    console.log('CallbackComponent()');
    if (this.route.snapshot.queryParamMap.get('error_description')) {
        this.error_description = this.route.snapshot.queryParamMap.get('error_description');
        console.error('CallbackComponent(): got error', this.error_description);
    } else {
      console.log('CallbackComponent(): handling callback');
      this.auth.handleAuthCallback();
    }
  }

  loginAsAnotherUser() {
    console.log('CallbackComponent(): loginAsAnotherUser()');
    this.auth.logout();
  }
}
