import { Config } from './config';
import ApolloClient from 'apollo-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AuthService } from './auth/auth.service';
import { HttpLink } from 'apollo-angular-link-http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiClientFactory {
  uri = `${Config.adminApiBaseUrl}/graphql`; // <-- add the URL of the GraphQL server here
  client: ApolloClient<any>;
  token: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    // do nothing
  }

  getClient() {
    const token = this.authService.getCurrentAuthToken();
    if (this.token === token) {
      console.log('ApiClientFactory(): using existing client');
      return this.client;
    }

    this.token = token;
    console.log('ApiClientFactory(): creating client with token');

    const httpLink = new HttpLink(this.httpClient);
    this.client = new ApolloClient({
      link: httpLink.create({
        uri: this.uri,
        headers: new HttpHeaders({
          Authorization: this.token
        })
      }),
      cache: new InMemoryCache({ addTypename: false })
    });

    return this.client;
  }
}
