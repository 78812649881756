import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import ImageEditor from 'tui-image-editor';

import mixinDeep from 'mixin-deep';
const clone = require('rfdc')();

import 'tui-image-editor/dist/svg/icon-a.svg';
import 'tui-image-editor/dist/svg/icon-b.svg';
import 'tui-image-editor/dist/svg/icon-c.svg';
import 'tui-image-editor/dist/svg/icon-d.svg';

enum editorEvents {
  addText = 'addText',
  mousedown = 'mousedown',
  objectActivated = 'objectActivated',
  objectMoved = 'objectMoved',
  objectScaled = 'objectScaled',
  redoStackChanged = 'redoStackChanged',
  textEditing = 'textEditing',
  undoStackChanged = 'undoStackChanged'
}
const includeUIOptions = {
  includeUI: {
    initMenu: 'filter'
  }
};
const editorDefaultOptions: IOptions = {
  cssMaxWidth: 700,
  cssMaxHeight: 500
};

interface IImageEditor extends ImageEditor {
  off(eventName: string): void;
}

@Component({
  selector: 'ngx-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['../../../node_modules/tui-image-editor/dist/tui-image-editor.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageEditorComponent implements AfterViewInit, OnDestroy {
  @Input() includeUI = true;
  @Input() options: IOptions;
  @Input() id: string;
  @Input() data: string;

  @Output() addText = new EventEmitter<IAddTextEvent>();
  @Output() mousedown = new EventEmitter<IMousedownEvent>();
  @Output() objectActivated = new EventEmitter<IGraphicObjectProps>();
  @Output() objectMoved = new EventEmitter<IGraphicObjectProps>();
  @Output() objectScaled = new EventEmitter<IGraphicObjectProps>();
  @Output() redoStackChanged = new EventEmitter<number>();
  @Output() textEditing = new EventEmitter<void>();
  @Output() undoStackChanged = new EventEmitter<number>();

  @ViewChild('imageEditor', { static: true }) editorRef: ElementRef;

  editorInstance: ImageEditor;

  async ngAfterViewInit() {
    console.log('ToastUiImageEditorComponent(): ngAfterViewInit()', this.data);
    await this.initImageEditor();
  }

  constructor() { }

  async initImageEditor() {
    // get defaults
    let options = mixinDeep({}, clone(editorDefaultOptions));
    console.log('options now', JSON.stringify(options));

    if (this.includeUI) {
      // get includeUI defaults
      options = mixinDeep(options, clone(includeUIOptions));
      console.log('options now', JSON.stringify(options));
      console.log('clone', clone(includeUIOptions));

      // get overrides
      options = mixinDeep(options, clone(this.options));
      console.log('options now', JSON.stringify(options));

      if (!!this.data && this.data.length > 0) {
        options = mixinDeep(options, {
          includeUI: {
            loadImage: {
              path: this.data,
              name: 'loaded image'
            }
          }
        });
      }

      console.log('options now', JSON.stringify(options));
    }

    // if we have an existing image editor, destroy it
    if (!!this.editorInstance) {
      console.log('destroying old image editor');
      this.editorInstance.destroy();
    }

    console.log('creating image editor with options', options);
    const editorInstance = new ImageEditor(
      this.editorRef.nativeElement,
      options
    );
    this.editorInstance = editorInstance;

    // Patch the loadImageFromURL of our tui imageEditor instance:
    this.editorInstance.loadImageFromURL = (() => {
      const cachedFunction = editorInstance.loadImageFromURL;
      function waitUntilImageEditorIsUnlocked(imageEditor) {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (!imageEditor._invoker._isLocked) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      return function() {
        return waitUntilImageEditorIsUnlocked(editorInstance).then(() => cachedFunction.apply(this, arguments));
      };
    })();

    this.addEventListeners();
  }

  ngOnDestroy() {
    this.removeEventListeners();
    this.editorInstance.destroy();
  }

  getImageData() {
    return this.editorInstance.toDataURL({format: 'png'});
  }

  private addEventListeners() {
    this.editorInstance.on(editorEvents.addText, event =>
      this.addText.emit(event)
    );
    this.editorInstance.on(editorEvents.mousedown, (event, originPointer) =>
      this.mousedown.emit({ event, originPointer })
    );
    this.editorInstance.on(editorEvents.objectActivated, event =>
      this.objectActivated.emit(event)
    );
    this.editorInstance.on(editorEvents.objectMoved, event =>
      this.objectMoved.emit(event)
    );
    this.editorInstance.on(editorEvents.objectScaled, event =>
      this.objectScaled.emit(event)
    );
    this.editorInstance.on(editorEvents.redoStackChanged, event =>
      this.redoStackChanged.emit(event)
    );
    this.editorInstance.on(editorEvents.textEditing, () =>
      this.textEditing.emit()
    );
    this.editorInstance.on(editorEvents.undoStackChanged, event =>
      this.undoStackChanged.emit(event)
    );
  }

  private removeEventListeners() {
    (this.editorInstance as IImageEditor).off(editorEvents.addText);
    (this.editorInstance as IImageEditor).off(editorEvents.mousedown);
    (this.editorInstance as IImageEditor).off(editorEvents.objectActivated);
    (this.editorInstance as IImageEditor).off(editorEvents.objectMoved);
    (this.editorInstance as IImageEditor).off(editorEvents.objectScaled);
    (this.editorInstance as IImageEditor).off(editorEvents.redoStackChanged);
    (this.editorInstance as IImageEditor).off(editorEvents.textEditing);
    (this.editorInstance as IImageEditor).off(editorEvents.undoStackChanged);
  }

}

export interface IPosition {
  x: number;
  y: number;
}

export interface IAddTextEvent {
  originPosition: IPosition;
  clientPosition: IPosition;
}

export interface IMousedownEvent {
  event: Event;
  originPointer: IPosition;
}

export interface IGraphicObjectProps {
  id?: number;
  type?: string;
  text?: string;
  left?: string | number;
  top?: string | number;
  width?: string | number;
  height?: string | number;
  fill?: string;
  stroke?: string;
  strokeWidth?: string | number;
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: string;
  fontWeight?: string;
  textAlign?: string;
  textDecoration?: string;
  opacity?: number;
  [propName: string]: number | string | boolean;
}

export interface IOptions {
  includeUI?: IIncludeUIOptions;
  cssMaxWidth?: number;
  cssMaxHeight?: number;
  usageStatistics?: boolean;
  selectionStyle?: ISelectionStyleConfig;
}

interface ISelectionStyleConfig {
  cornerStyle?: string;
  cornerSize?: number;
  cornerColor?: string;
  cornerStrokeColor?: string;
  transparentCorners?: boolean;
  lineWidth?: number;
  borderColor?: string;
  rotatingPointOffset?: number;
}

interface IIncludeUIOptions {
  loadImage?: {
    path: string;
    name: string;
  };
  theme?: IThemeConfig;
  menu?: string[];
  initMenu?: string;
  uiSize?: {
    width: string;
    height: string;
  };
  menuBarPosition?: string;
}

interface IThemeConfig {
  'common.bi.image'?: string;
  'common.bisize.width'?: string;
  'common.bisize.height'?: string;
  'common.backgroundImage'?: string;
  'common.backgroundColor'?: string;
  'common.border'?: string;
  'header.backgroundImage'?: string;
  'header.backgroundColor'?: string;
  'header.border'?: string;
  'loadButton.backgroundColor'?: string;
  'loadButton.border'?: string;
  'loadButton.color'?: string;
  'loadButton.fontFamily'?: string;
  'loadButton.fontSize'?: string;
  'downloadButton.backgroundColor'?: string;
  'downloadButton.border'?: string;
  'downloadButton.color'?: string;
  'downloadButton.fontFamily'?: string;
  'downloadButton.fontSize'?: string;
  'menu.normalIcon.path'?: string;
  'menu.normalIcon.name'?: string;
  'menu.activeIcon.path'?: string;
  'menu.activeIcon.name'?: string;
  'menu.iconSize.width'?: string;
  'menu.iconSize.height'?: string;
  'submenu.backgroundColor'?: string;
  'submenu.partition.color'?: string;
  'submenu.normalIcon.path'?: string;
  'submenu.normalIcon.name'?: string;
  'submenu.activeIcon.path'?: string;
  'submenu.activeIcon.name'?: string;
  'submenu.iconSize.width'?: string;
  'submenu.iconSize.height'?: string;
  'submenu.normalLabel.color'?: string;
  'submenu.normalLabel.fontWeight'?: string;
  'submenu.activeLabel.color'?: string;
  'submenu.activeLabel.fontWeight'?: string;
  'checkbox.border'?: string;
  'checkbox.backgroundColor'?: string;
  'range.pointer.color'?: string;
  'range.bar.color'?: string;
  'range.subbar.color'?: string;
  'range.value.color'?: string;
  'range.value.fontWeight'?: string;
  'range.value.fontSize'?: string;
  'range.value.border'?: string;
  'range.value.backgroundColor'?: string;
  'range.title.color'?: string;
  'range.title.fontWeight'?: string;
  'colorpicker.button.border'?: string;
  'colorpicker.title.color'?: string;
}
