import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UpdateProfileAction, LogoutAction } from './app.actions';
import { ApplicationStateHandlers, ApplicationStateModel } from './app.state.handlers';
import { Observable } from 'rxjs';

@Injectable()
export class AppSandbox {
  @Select(ApplicationStateHandlers.profile)
  public profile$: Observable<any>;

  constructor(private store: Store) {
  }

  updateProfile(profile: any): void {
    this.store.dispatch(new UpdateProfileAction(profile));
  }

  logout(): void {
    this.store.dispatch(new LogoutAction());
  }

}
