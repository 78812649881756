import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { AppSandbox } from '../../app.sandbox';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService, private appSandbox: AppSandbox) { }

  ngOnInit() {
    console.log('LogoutComponent(): logout');
    this.appSandbox.logout();
    this.authService.logout();
  }

}
