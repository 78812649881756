import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule, NbWindowModule
  // NbWindowModule
} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { CoreModule } from './@core/core.module';
import { GraphQLModule } from '../graphql.module';
import { ThemeModule } from './@theme/theme.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { CallbackComponent } from './callback/callback.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ApplicationStateHandlers } from './app.state.handlers';
import { AppSandbox } from './app.sandbox';
import { LogoutComponent } from './pages/logout/logout.component';
import { environment } from '../environments/environment';
import { Config } from './config';
import { ApiClientFactory } from './api.client';

@NgModule({
  declarations: [
    AppComponent,
    ImageEditorComponent,
    CallbackComponent,
    LoadingComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    ThemeModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    // GraphQLModule,
    CommonModule,
    PopoverModule.forRoot(),

    NgxsModule.forRoot([
      ApplicationStateHandlers
    ], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['app']
    }),
    NgxsLoggerPluginModule.forRoot({ collapsed: true }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
  providers: [
    AuthService,
    AppSandbox,
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient) => {
        return () => {
          return http.get('/config.json')
            .toPromise()
            .then((res: any) => {
              console.log('loaded config', res);

              if (!!res && !!res.adminApiBaseUrl) {
                Config.adminApiBaseUrl = res.adminApiBaseUrl;
              } else {
                Config.adminApiBaseUrl = res.adminApiBaseUrl;
              }

              if (!!res && !!res.contentApiBaseUrl) {
                Config.contentApiBaseUrl = res.contentApiBaseUrl;
              } else {
                Config.contentApiBaseUrl = res.contentApiBaseUrl;
              }

              console.log('using base URLs', Config.adminApiBaseUrl, Config.contentApiBaseUrl);
            });
        };
      },
      multi: true,
      deps: [HttpClient]
    },
    ApiClientFactory
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
