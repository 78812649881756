import { State, Selector, Action, StateContext } from '@ngxs/store';
import { LogoutAction, UpdateProfileAction } from './app.actions';

export class ApplicationStateModel {
  public profile: any;
}

@State<ApplicationStateModel>({
  name: 'app',
  defaults: {
    profile: null
  } as ApplicationStateModel
})
export class ApplicationStateHandlers {
  @Selector()
  static profile(state: ApplicationStateModel): any {
    return state.profile;
  }

  /**
   * login
   */
  @Action(UpdateProfileAction)
  login({ getState, patchState }: StateContext<ApplicationStateModel>, { profile }: UpdateProfileAction) {
    console.log('ApplicationState(): login()', profile);
    patchState({
      profile: profile
    });
  }

  /**
   * logout
   */
  @Action(LogoutAction)
  logout({ getState, patchState }: StateContext<ApplicationStateModel>) {
    console.log('ApplicationState(): logout()');
    patchState({
      profile: null
    });
  }
}
